import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form, Input, ConfigProvider, Radio, Select, Button, Tooltip } from 'antd';

import { InfoCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { getDataset } from '../../../../redux/actions/dataset-action';


import DynamicSelectbox from "../../../dynamicSelectbox/index";

import '../../../../styles/timeoffTypes.scss';

import { createInitialList, compareObjects} from '../../../../utils/commonFormFunctions'
import { UilPlus, UilMinusCircle } from '@iconscout/react-unicons';


function UpdateTimeoffTypeBasicInfo(props) {
    const { t } = useTranslation();

    const [form] = Form.useForm()
    const { dataset, getDataset, setUpdateTimeoffTypeObject, timeoffType } = props;

    const [paymentTypesList, setPaymentTypes] = useState(createInitialList(timeoffType?.paymentType));
    const [unitSetList, setUnitSetList] = useState([]);
    const [accrualUnitTypesList, setAccrualUnitTypesList] = useState([]);
    const [accrualDurationTypesList, setAccrualDurationTypesList] = useState([]);
    const [basicInfoState, setBasicInfoState] = useState({});
    const [annualLeaveList, setAnnualLeaveList] = useState([]);
    const [endYearErrors, setEndYearErrors] = useState({});

    const isAnnualLeaveItemChanged = (index, field) => {
        if (!timeoffType?.annualLeaveSettingList?.[index]) return false;
        const originalValue = timeoffType.annualLeaveSettingList[index][field];
        const currentValue = annualLeaveList[index][field];
        return originalValue !== currentValue;
    };

    useEffect(() => {
        setBasicInfoState({
            ...timeoffType
        })
        form.setFieldsValue({
            ...timeoffType
        })
        if (timeoffType?.annualLeaveSettingList) {
            // Deep copy to avoid reference issues
            setAnnualLeaveList(JSON.parse(JSON.stringify(timeoffType.annualLeaveSettingList)));
        }
    }, [form, timeoffType])

    useEffect(() => {
        setUpdateTimeoffTypeObject(basicInfoState)
    }, [basicInfoState]);

    const allowOnlyNumericKeyPress = (e) => {
        const key = e.key;
        if (!/[0-9]/.test(key)) {
          e.preventDefault();
        }
    };
    const allowOnlyNumericKeyDotPress = (e) => {
        const key = e.key;
        const value = e.target.value;
        
        // Allow only numbers and one decimal point
        if (!/[0-9.]/.test(key)) {
            e.preventDefault();
            return;
        }

        // If key is decimal point
        if (key === '.') {
            // Prevent if there's already a decimal point
            if (value.includes('.')) {
                e.preventDefault();
                return;
            }
        }
    };


    const allowOnlyNumericPaste = (e) => {
        const pasteData = e.clipboardData.getData('text/plain');
        if (!/^[0-9]*$/.test(pasteData)) {
          e.preventDefault();
        }
    };

    const allowOnlyNumericDotPaste = (e) => {
        const pasteData = e.clipboardData.getData('text/plain');
        const currentValue = e.target.value;
        const newValue = currentValue + pasteData;
        
        // Check if the resulting value would be a valid number with at most one decimal point
        if (!/^\d*\.?\d*$/.test(newValue) || (newValue.match(/\./g) || []).length > 1) {
            e.preventDefault();
        }
    };


    


    useEffect(() => {
        setPaymentTypes(dataset.paymentTypes)
    }, [dataset.paymentTypes])

    useEffect(() => {
        setUnitSetList(dataset.unitTypes);
    }, [dataset.unitTypes]);

    useEffect(() => {
        setAccrualUnitTypesList(dataset.accrualUnitTypes);
    }, [dataset.accrualUnitTypes]);

    useEffect(() => {
        setAccrualDurationTypesList(dataset.accrualDurationTypes);
    }, [dataset.accrualDurationTypes]);


    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if (key === 'annualLeaveSettingList') {
            const newList = [...annualLeaveList];
            const index = changedObj.index;
            const field = changedObj.field;
            const value = changedObj.value;
            
            newList[index][field] = value;
            setAnnualLeaveList(newList);
            setBasicInfoState({ ...basicInfoState, annualLeaveSettingList: newList });
        } else if (key === 'paymentType') {
            setBasicInfoState({ ...basicInfoState, 'paymentType': changedObj["paymentType"] });
        }
        else if( key=== 'isBalanceUnlimited'){
            if(changedObj["isBalanceUnlimited"] !== timeoffType.isBalanceUnlimited){
                setBasicInfoState({ ...basicInfoState, isBalanceUnlimited: changedObj["isBalanceUnlimited"], 'accrualUnit': null , 'maxBalance':null, 'unitType': null  });
                form.setFieldsValue({
                    "accrualUnit":{ "id": null, "displayValue": null },
                    "unitType": { "id": null, "displayValue": null },
                    "maxBalance": null
                })
            }else{
                setBasicInfoState({ ...basicInfoState, isBalanceUnlimited: changedObj["isBalanceUnlimited"], 'accrualUnit': timeoffType.accrualUnit , 'maxBalance':timeoffType.maxBalance, 'unitType': timeoffType.unitType  });
                form.setFieldsValue({
                    "accrualUnit":timeoffType.accrualUnit,
                    "unitType": timeoffType.unitType,
                    "maxBalance": timeoffType.maxBalance
                })
            }
        }
        else if (key === 'accrualUnit') {
            if(changedObj["accrualUnit"]?.id != timeoffType.accrualUnit?.id){
                setBasicInfoState({ ...basicInfoState, 'accrualUnit': changedObj["accrualUnit"] , 'maxBalance':null, 'unitType': null  });
                form.setFieldsValue({
                    "unitType": { "id": null, "displayValue": null },
                    "maxBalance": null
                })
            }else{
                setBasicInfoState({ ...basicInfoState, 'accrualUnit': changedObj["accrualUnit"] , 'maxBalance':timeoffType.maxBalance, 'unitType': timeoffType.unitType  });
                form.setFieldsValue({
                    "unitType": timeoffType.unitType,
                    "maxBalance": timeoffType.maxBalance
                })
            }
            if(changedObj["accrualUnit"]?.id == 2){
                const filteredunitSetList = unitSetList.filter(u => u?.id == 3 );
                setUnitSetList(filteredunitSetList)
            }else{
                setUnitSetList(dataset.unitTypes)
            }
        }
        else if (key === 'unitType') {
            if(changedObj["unitType"]?.id != timeoffType.unitType?.id){
                setBasicInfoState({ ...basicInfoState, 'unitType': changedObj["unitType"], 'maxBalance':null });
                form.setFieldsValue({
                    "maxBalance": null
                })
            }else{
                setBasicInfoState({ ...basicInfoState, 'unitType': changedObj["unitType"], 'maxBalance': timeoffType.maxBalance });
                form.setFieldsValue({
                    "maxBalance": timeoffType.maxBalance
                })
            }
        }
        else if (key === 'accrualDurationType') {
            setBasicInfoState({ ...basicInfoState, 'accrualDurationType': changedObj["accrualDurationType"] });
        }
        else if (key === 'maxBalance') {
            let maxValueList = {
                5:365, //Yıl
                4:30, //Ay
                3:7, //Hafta
                2:24 //Gün
            }
            const maxValueMultiplier = {
                1:1, //günlük
                3:24 //saatlik
            }
            let maxValue = maxValueList[basicInfoState.accrualUnit] * ( basicInfoState.accrualUnit == 2 ? 1 : maxValueMultiplier[basicInfoState.unitType] || 1) ;
            let minValue = 1;

            let filteredValue = changedObj["maxBalance"]
            if(changedObj["maxBalance"]>maxValue){
                filteredValue = maxValue
            }else if(changedObj["maxBalance"] < minValue &&  changedObj["maxBalance"] != ''){
                filteredValue = minValue
            }
            setBasicInfoState({ ...basicInfoState, 'maxBalance': filteredValue  });
            form.setFieldsValue({"maxBalance": filteredValue})
        }
        else if( key=='accrualDateType'){
            if(changedObj["accrualDateType"] != timeoffType.accrualDateType){
                setBasicInfoState({ ...basicInfoState, 'accrualDateType': changedObj["accrualDateType"], 'accrualDuration':null, 'accrualDurationType':null });
                form.setFieldsValue({
                    "accrualDuration": null,
                    "accrualDurationType": { "id": null, "displayValue": null }
                })
            }else{
                setBasicInfoState({ ...basicInfoState, 'accrualDateType': changedObj["accrualDateType"], 'accrualDuration':timeoffType.accrualDuration, 'accrualDurationType':timeoffType.accrualDurationType });
                form.setFieldsValue({
                    "accrualDuration": timeoffType.accrualDuration,
                    "accrualDurationType": timeoffType.accrualDurationType
                })
            }
        }
        else {
            setBasicInfoState({ ...basicInfoState, ...changedObj });
        }
    }

    const accrualAfter = (
        <Form.Item className="add-user-draw-required-input" name="accrualDurationType" required>
            <DynamicSelectbox
                optionList={accrualDurationTypesList}
                onFocus={() => getDataset(42)}
                placeholder={t('TIMEOFF_SELECT_PLACEHOLDER')}
                size="large"
                className={!compareObjects(timeoffType?.accrualDurationType?.id, basicInfoState?.accrualDurationType?.id) ? 'is-changed-group-select' : ''}
            />
        </Form.Item>
    );

    const addNewAnnualLeaveSetting = () => {
        const lastItem = annualLeaveList[annualLeaveList.length - 1];
        const newStartYear = lastItem ? lastItem.endYear + 1 : 0;
        const newItem = {
            startYear: newStartYear,
            endYear: newStartYear + 1,
            leaveCount: 0
        };
        const newList = [...annualLeaveList, newItem];
        setAnnualLeaveList(newList);
        setBasicInfoState(prevState => ({
            ...prevState,
            annualLeaveSettingList: newList
        }));

        // Add is-changed class to the parent element
        const annualLeaveSection = document.querySelector('.annual-leave-settings');
        if (annualLeaveSection && !annualLeaveSection.classList.contains('is-changed-add')) {
            annualLeaveSection.classList.add('is-changed-add');
        }
    };

    const removeAnnualLeaveSetting = (index) => {
        const newList = annualLeaveList.filter((_, i) => i !== index);
        setAnnualLeaveList(newList);
        setBasicInfoState(prevState => ({
            ...prevState,
            annualLeaveSettingList: newList
        }));

        // Add is-changed class to the parent element
        const annualLeaveSection = document.querySelector('.annual-leave-settings');
        if (annualLeaveSection && !annualLeaveSection.classList.contains('is-changed-add')) {
            annualLeaveSection.classList.add('is-changed-add');
        }
    };

    const handleAnnualLeaveChange = (index, field, value) => {
        const newList = [...annualLeaveList];
        const parsedValue = parseInt(value) || 0;

        if (field === 'endYear') {
            const currentStartYear = newList[index].startYear;
            newList[index] = {
                ...newList[index],
                [field]: parsedValue
            };

            // Check if endYear is valid - now allowing equal values
            if (parsedValue < currentStartYear) {
                setEndYearErrors({
                    ...endYearErrors,
                    [index]: true
                });
            } else {
                setEndYearErrors({
                    ...endYearErrors,
                    [index]: false
                });
            }

            // If there's a next item, update its startYear and endYear if needed
            if (index < newList.length - 1) {
                const nextStartYear = parsedValue + 1;
                newList[index + 1] = {
                    ...newList[index + 1],
                    startYear: nextStartYear,
                    // If current endYear is less than or equal to new startYear, update it
                    endYear: newList[index + 1].endYear < nextStartYear ? nextStartYear : newList[index + 1].endYear
                };

                // Update subsequent items if needed
                for (let i = index + 2; i < newList.length; i++) {
                    const prevEndYear = newList[i - 1].endYear;
                    const nextStartYear = prevEndYear + 1;
                    newList[i] = {
                        ...newList[i],
                        startYear: nextStartYear,
                        // If current endYear is less than new startYear, update it
                        endYear: newList[i].endYear < nextStartYear ? nextStartYear : newList[i].endYear
                    };
                }
            }
        } else {
            // For other fields (startYear and leaveCount)
            newList[index] = {
                ...newList[index],
                [field]: parsedValue
            };
        }

        setAnnualLeaveList(newList);
        setBasicInfoState(prevState => ({
            ...prevState,
            annualLeaveSettingList: newList
        }));

        // Add is-changed class to the parent element
        const annualLeaveSection = document.querySelector('.annual-leave-settings');
        if (annualLeaveSection && !annualLeaveSection.classList.contains('is-changed-add')) {
            annualLeaveSection.classList.add('is-changed-add');
        }
    };

    const handleEndYearBlur = (index) => {
        if (endYearErrors[index]) {
            const newList = [...annualLeaveList];
            const currentStartYear = newList[index].startYear;
            newList[index] = {
                ...newList[index],
                endYear: currentStartYear // Set to same as startYear instead of startYear + 1
            };

            setEndYearErrors({
                ...endYearErrors,
                [index]: false
            });

            // If there's a next item, update its startYear and endYear if needed
            if (index < newList.length - 1) {
                const nextStartYear = currentStartYear + 1; // Just currentStartYear + 1
                newList[index + 1] = {
                    ...newList[index + 1],
                    startYear: nextStartYear,
                    endYear: newList[index + 1].endYear < nextStartYear ? nextStartYear : newList[index + 1].endYear
                };

                // Update subsequent items if needed
                for (let i = index + 2; i < newList.length; i++) {
                    const prevEndYear = newList[i - 1].endYear;
                    const nextStartYear = prevEndYear + 1;
                    newList[i] = {
                        ...newList[i],
                        startYear: nextStartYear,
                        endYear: newList[i].endYear < nextStartYear ? nextStartYear : newList[i].endYear
                    };
                }
            }

            setAnnualLeaveList(newList);
            setBasicInfoState(prevState => ({
                ...prevState,
                annualLeaveSettingList: newList
            }));
        }
    };

    return (
        
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={form}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                    requiredMark={true}
                >
                    <Form.Item className="max-width-50per" name="name" label={t('TIMEOFF_NAME') + " *"} required>
                        <Input placeholder={t('TIMEOFF_NAME_PLACEHOLDER')} className={timeoffType.name !== basicInfoState.name ? 'is-changed' : ''} />
                    </Form.Item>
                    <Form.Item className="max-width-50per" name="paymentType" label={t('TIMEOFF_TYPE') + " *"} required>
                        <DynamicSelectbox
                            optionList={paymentTypesList}
                            onFocus={() => getDataset(1)}
                            placeholder={t('TIMEOFF_TYPE_PLACEHOLDER')}
                            size="large"
                            className={!compareObjects(timeoffType?.paymentType?.id, basicInfoState?.paymentType?.id) ? 'is-changed' : ''}
                        />
                    </Form.Item>
                    <Form.Item name="description" className="max-width-50per custom-select" label={t('TIMEOFF_DESCRIPTION')}>
                        <Input.TextArea placeholder={t("TIMEOFF_DESCRIPTION_PLACEHOLDER")} className={timeoffType.description !== basicInfoState.description ? 'is-changed' : ''} />
                    </Form.Item>
                    { basicInfoState.timeOffSetIdx !== 1 && (
                    <Form.Item className="margin-b-12 max-width-50per" name="isBalanceUnlimited" label={t('TIMEOFF_DURATION') + " *"} >
                        <Radio.Group>
                            <Radio value={true} className={timeoffType.isBalanceUnlimited !== basicInfoState.isBalanceUnlimited ? 'is-changed-radio' : ''}>{t('TIMEOFF_LIMITLESS')}</Radio>
                            <Radio value={false} className={timeoffType.isBalanceUnlimited !== basicInfoState.isBalanceUnlimited ? 'is-changed-radio' : ''}>{t('TIMEOFF_LIMITED')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}
                    { basicInfoState?.isBalanceUnlimited == 0 && basicInfoState.timeOffSetIdx !== 1 && (<>
                    <Row gutter={16} className="max-width-50per">
                        <Col span={12}>
                            <Form.Item className="add-user-draw-required-input" name="accrualUnit" label={t('TIMEOFF_LIMITED_LIMIT') + " *"}>
                                <DynamicSelectbox
                                    optionList={accrualUnitTypesList}
                                    onFocus={() => getDataset(6)} 
                                    placeholder={t('TIMEOFF_SELECT_PLACEHOLDER')}
                                    size="large"
                                    className={!compareObjects(timeoffType?.accrualUnit?.id, basicInfoState?.accrualUnit?.id) ? 'is-changed' : ''}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item className="add-user-draw-required-input no-label-input-in-row" name="unitType">
                                <DynamicSelectbox
                                    optionList={unitSetList}
                                    onFocus={() => getDataset(4)}
                                    placeholder={t('TIMEOFF_SELECT_PLACEHOLDER')}
                                    size="large"
                                    disabled={!basicInfoState.accrualUnit}
                                    className={!compareObjects(timeoffType?.unitType?.id, basicInfoState?.unitType?.id) ? 'is-changed' : ''}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="margin-b-12">
                        <Col className="d-flex align-items-center">
                            <span className="dotted-underline">{dataset.accrualUnitTypes?.filter( item => item.id == basicInfoState?.accrualUnit)[0]?.displayValue || basicInfoState?.accrualUnit?.displayValue }</span>
                            <Form.Item className="margin-b-12 px-3 delete-draw-label custom-input-group max-width-50per " name="maxBalance" >
                                <Input 
                                    size="large" disabled={!basicInfoState.accrualUnit || !basicInfoState.unitType}
                                    onKeyPress={allowOnlyNumericKeyDotPress} onPaste={allowOnlyNumericDotPaste} 
                                    placeholder={t("TIMEOFF_LIMITED_PLACEHOLDER")}
                                    className={timeoffType.maxBalance != basicInfoState.maxBalance ? 'is-changed' : ''} 
                                />
                            </Form.Item>
                            <span className="dotted-underline me-3">{dataset.unitTypes?.filter( item => item.id == basicInfoState?.unitType)[0]?.displayValue || basicInfoState?.unitType?.displayValue}</span>
                            <span> {t('TIMEOFF_BALANCE_LIMIT_POSTFIX')}</span>
                        </Col>
                    </Row>
                    </>)}
                    {basicInfoState.timeOffSetIdx == 1 && (
                        <div className="annual-leave-settings" style={{ border: '0px !important' }}>
                            <div class="ant-col ant-form-item-label"><label for="description" class="" title="Açıklama">{t('TIMEOFF_TIME_OFF_DURATION')}</label></div>
                            <div className="f-14 mb-3 secondary-dark-font-color">
                                {t('TIMEOFF_LIMITED_AND_SENIORITY')}
                            </div>
                            <div className="annual-leave-list">
                                {annualLeaveList.map((item, index) => (
                                    <Row key={index} gutter={16} className="annual-leave-item d-flex align-items-center">
                                        <Col span={4}>
                                            <Input
                                                value={item.startYear}
                                                onChange={(e) => handleAnnualLeaveChange(index, 'startYear', e.target.value)}
                                                onKeyPress={allowOnlyNumericKeyPress}
                                                onPaste={allowOnlyNumericPaste}
                                                placeholder={t('TIMEOFF_YEAR_PLACEHOLDER_NEW')}
                                                className={isAnnualLeaveItemChanged(index, 'startYear') ? 'is-changed' : ''}
                                                disabled={index > 0}
                                            />
                                        </Col>
                                        <Col span={2} className="text-center f-14">
                                            {index === annualLeaveList.length - 1 ? t('TIMEOFF_YEAR_WITH_AND_NEW') : t('TIMEOFF_YEAR_WITH_NEW')}
                                        </Col>
                                        <Col span={4}>
                                            {index === annualLeaveList.length - 1 ? (
                                                <div className="text-center f-14" style={{ lineHeight: '32px' }}>
                                                    {item.startYear} {t('TIMEOFF_YEAR_AND_AFTER')}
                                                </div>
                                            ) : (
                                                <div>
                                                    <Input
                                                        value={item.endYear}
                                                        onChange={(e) => handleAnnualLeaveChange(index, 'endYear', e.target.value)}
                                                        onBlur={() => handleEndYearBlur(index)}
                                                        onKeyPress={allowOnlyNumericKeyPress}
                                                        onPaste={allowOnlyNumericPaste}
                                                        placeholder={t('TIMEOFF_YEAR_PLACEHOLDER_NEW')}
                                                        className={`${isAnnualLeaveItemChanged(index, 'endYear') ? 'is-changed' : ''} ${endYearErrors[index] ? 'input-error' : ''}`}
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                        <Col span={3} className="text-center">
                                            {index === annualLeaveList.length - 1 ? '' : t('TIMEOFF_YEAR_BETWEEN_NEW')}
                                        </Col>
                                        <Col span={4}>
                                            <Input
                                                value={item.leaveCount}
                                                onChange={(e) => handleAnnualLeaveChange(index, 'leaveCount', e.target.value)}
                                                onKeyPress={allowOnlyNumericKeyPress}
                                                onPaste={allowOnlyNumericPaste}
                                                placeholder={t('TIMEOFF_COUNT_PLACEHOLDER_NEW')}
                                                className={isAnnualLeaveItemChanged(index, 'leaveCount') ? 'is-changed' : ''}
                                            />
                                        </Col>
                                        <Col span={5} className="text-center">
                                            {t('TIMEOFF_DAYS_EARNED_NEW')}
                                        </Col>
                                        <Col span={2} className="text-center">
                                            {annualLeaveList.length > 1 && (
                                                <Tooltip title="Sil">
                                                    <UilMinusCircle 
                                                        onClick={() => removeAnnualLeaveSetting(index)} 
                                                        className="cursor-pointer white-btn" 
                                                    />
                                                </Tooltip>
                                            )}
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                            {Object.values(endYearErrors).some(error => error) && (
                                <div className="error-message">
                                    {t('TIMEOFF_END_YEAR_ERROR')}
                                </div>
                            )}
                            <Button onClick={addNewAnnualLeaveSetting} className="white-btn flex-align-center svg-class f-14 mr-2">
                                <UilPlus /> {t('TIME_OFF_REQUEST_NEW_ADD')}
                            </Button>
                        </div>
                    )}
                    <Form.Item className="margin-b-12 delete-draw-label custom-label-tooltip" name="accrualDateType" label={t('TIMEOFF_STARTING_TIME') + " *"} tooltip={{
                    title: t('TIMEOFF_ACRUAL_DESC'),
                    icon: <InfoCircleOutlined />,
                    placement:'top'
                    }} >
                    <Radio.Group>
                        <Radio value="1" className={timeoffType.accrualDateType !== basicInfoState.accrualDateType ? 'is-changed-radio' : ''}>{t('TIMEOFF_STARTING_TIME_OPTION_1')}</Radio>
                        <Radio value="3" className={timeoffType.accrualDateType !== basicInfoState.accrualDateType ? 'is-changed-radio' : ''}>{t('TIMEOFF_STARTING_TIME_OPTION_2')}</Radio>
                    </Radio.Group>
                    </Form.Item>
                    { basicInfoState?.accrualDateType == 3 &&
                    <div className="d-flex align-items-center">
                        <span style={{minWidth:'fit-content',marginRight:'16px'}}>{t("TIMEOFF_START_TIME_FROM")} </span>
                        <Form.Item className="delete-draw-label custom-input-group mb-0" name="accrualDuration">
                            <Input 
                                style={{maxWidth: '245px'}} addonAfter={accrualAfter} 
                                onKeyPress={allowOnlyNumericKeyPress} onPaste={allowOnlyNumericPaste} 
                                placeholder={t("TIMEOFF_LIMITED_PLACEHOLDER")} 
                                className={timeoffType.accrualDuration != basicInfoState.accrualDuration ? 'is-changed-group-input' : ''}
                            />
                        </Form.Item>
                        <span style={{minWidth:'fit-content',marginLeft:'16px'}}>{t("TIMEOFF_START_TIME_AFTER")}</span>
                    </div>
                    }
                </Form>
            </ConfigProvider>
    )
}
const mapStateToProps = (state) => {
    return {
        dataset: state.dataset
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataset: (lookupId) => { dispatch(getDataset(lookupId)) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTimeoffTypeBasicInfo);