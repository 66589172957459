import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { getOrganizationTimeoffTypeList, changeTimeoffTypeStatus, deleteTimeoffType, copyTimeoffType,
     resetTimeoffTypeResponses, setDetailTimeoffTypeDrawer, getTimeoffTypeDetail  } from '../../redux/actions/timeoffTypes.action';

import { Button, Dropdown, Switch, Modal, Radio, Space, Tooltip } from 'antd';
import { PlusOutlined, MoreOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Media from 'react-media';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicToastMessage from '../dynamicToastMessage/index';
import { timeoffTypeDropdown } from '../../features/global-dropdown-menu/index';
import { useWindowDimensions } from '../../utils/config';
import DynamicRequestModal from '../dynamicModal/requestModal';

import { updateTimeOffRequest, updateTimeOffRequestReset } from '../../redux/actions/notification.actions';


function TimeOffTypesTab(props) {
    const { t } = useTranslation();
    const [isShowPassives, setIsShowPassives] = useState(false);
    const { width } = useWindowDimensions();
    const { createTimeoffTypeDraw, getOrganizationTimeoffTypeList, organizationTimeoffTypeListLoading, resetTimeoffTypeResponses, getTimeoffTypeDetail,
        setDetailTimeoffTypeDrawer, organizationTimeoffTypeList, changeTimeoffTypeStatus, updateStatusTimeoffTypeResponse, deleteTimeoffType, 
        deleteTimeoffTypeResponse, copyTimeoffType, copyTimeoffTypeResponse, updateTimeOffRequest, notificationUpdateResponse, updateTimeOffRequestReset,
        organizationBirthdayRule} = props;

    const [deleteModalData, setDeleteModalData] = useState({ "isVisible": false });

    const [isEditSettingsModalOpen, setIsEditSettingsModalOpen] = useState(false);
    const [birthDayRule, setBirthDayRule] = useState(organizationBirthdayRule);
    
    const handleEditSettingsModalClose = () => {
        setIsEditSettingsModalOpen(false);
    };

    const handleSaveGridSettings = (d) => { }

    const handleResetAllfilters = () => {
        setIsShowPassives(false)
    }
    const handleTableFilter = (data) => {
    }
    useEffect(() => {
        getOrganizationTimeoffTypeList()
    }, []);
    
    useEffect(() => {

    }, [organizationTimeoffTypeList]);

    useEffect(() => {
        setBirthDayRule(organizationBirthdayRule)
    }, [organizationBirthdayRule]);

    useEffect(() => {
        if(updateStatusTimeoffTypeResponse.status == "SUCCESS"){
            DynamicToastMessage({ 
                'key': 'change_status_success',
                'title': t('SUCCESS'),
                'description': updateStatusTimeoffTypeResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getOrganizationTimeoffTypeList()
            resetTimeoffTypeResponses()
        }
        if(updateStatusTimeoffTypeResponse.status == "FAIL"){
            DynamicToastMessage({ 
                'key': 'change_status_fail',
                'title': t('WARNING'),
                'description': updateStatusTimeoffTypeResponse?.message?.message ? updateStatusTimeoffTypeResponse?.message?.message : updateStatusTimeoffTypeResponse?.message,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }, [updateStatusTimeoffTypeResponse]);

    useEffect(() => {
        if(deleteTimeoffTypeResponse.status == "SUCCESS"){
            DynamicToastMessage({ 
                'key': 'delete_success',
                'title': t('SUCCESS'),
                'description': deleteTimeoffTypeResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getOrganizationTimeoffTypeList()
            resetTimeoffTypeResponses()
        }else if(deleteTimeoffTypeResponse.status == "FAIL"){
            DynamicToastMessage({ 
                'key': 'delete_fail',
                'title': t('WARNING'),
                'description': deleteTimeoffTypeResponse.message,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
            resetTimeoffTypeResponses()
        }
    }, [deleteTimeoffTypeResponse]);

    useEffect(() => {
        if(copyTimeoffTypeResponse.status == "SUCCESS"){
            DynamicToastMessage({ 
                'key': 'change_status_success',
                'title': t('SUCCESS'),
                'description': copyTimeoffTypeResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getOrganizationTimeoffTypeList()
            resetTimeoffTypeResponses()
        }
    }, [copyTimeoffTypeResponse]);

    useEffect(() => {
        if (notificationUpdateResponse.status === 'SUCCESS') {
            DynamicToastMessage({ 
                'key': 'change_status_success',
                'title': t('SUCCESS'),
                'description': notificationUpdateResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            getOrganizationTimeoffTypeList()
            updateTimeOffRequestReset()
            handleEditSettingsModalClose()
        }
    }, [notificationUpdateResponse])
    

    const tableConfig = () => ({
        filterableColumns: width >= 875 ? [] : [],
        sortableColumns: [],
        visibilityOptionExcludes: [],
        page: "TimeOffTypes",
        leftActionMenu: null,
        showVisibilityMenu: false,
    });
    const paginationConfig = () => ({
        // total: totalNumberOfRecords,
        // showSizeChanger: true,
        // locale: { items_per_page: "/" + t('PAGE') },
        // position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    });

    const handleTableChange = (data) => {
        //const paginationObj = { ...roleState.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data?.sorter?.field ? data?.sorter?.field : roleState?.pagination?.orderBy }
        // if (data.sorter.order) {
        //     paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        // }
        // setRolState({ ...roleState, 'pagination': paginationObj })
    }
    const handleTableSearch = (e) => {
        // const pagingObj = { ...roleState.pagination, "pageNumber": 1 }
        // setRolState({ ...roleState, "SearchTerm": e, "pagination": pagingObj })
    }
    const handleShowPassivesToogle = () => { }

    const onChangeTimeoffTypeStatus = (status, id, timeOffSetIdx) => {
        changeTimeoffTypeStatus({status: status, id: id})
    }

    const data = organizationTimeoffTypeList?.map((row) => {
        return {
            "name": {'name':row.name,'id':row.id, 'isDefault': row.isDefault , 'timeOffSetIdx': row.timeOffSetIdx},
            "paymentType": row.paymentType,
            "maxBalance": row.maxBalance,
            "unit": row.unit,
            "isActive": {
                'id': row.id, 
                'status': row.isActive,
                'timeOffSetIdx': row.timeOffSetIdx  // timeOffSetIdx değerini isActive objesine ekledik
            }
        };
    })

    const confirmDeleteModal = (id, name) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'name': name, 'isVisible': true });
    }

    const handleCopyTimeoffType = (id) => {
        copyTimeoffType(id)
    }

    const handleSettingsChange = (id) => {
        setBirthDayRule(id.target.value)
    }

    const handleSaveSettingsChange = () => {
        updateTimeOffRequest(birthDayRule)
    }

    const handleEditTimeoffType = (id) => {
        setDetailTimeoffTypeDrawer(true)
        getTimeoffTypeDetail(id)
    }

    const handleEditSettingsTimeoffType = () => {
        setIsEditSettingsModalOpen(true);
    };

    return (
        <div className="table-wrapper">
            <DynamicRequestModal
                modalVisible={deleteModalData.isVisible}
                setStateData={setDeleteModalData}
                postAction={deleteTimeoffType}
                confirmData={deleteModalData.id}
                iconName='UilInfoCircle'
                iconColor='#FFE651'
                description={t('TIMEOFF_TYPE_CONFIRM_DELETE_MESSAGE')}
                cancelBtnTxt={t('TIMEOFF_TYPE_CONFIRM_DELETE_NO')}
                successBtnTxt={t('TIMEOFF_TYPE_CONFIRM_DELETE_OK')}
            />
            <Modal className="birthday-timeoff-modal" title={t('TIMEOFF_BIRTHDAY_SETTINGS_MODAL_TITLE')} visible={isEditSettingsModalOpen} onCancel={handleEditSettingsModalClose}
            footer={[
                <Button key="save" className="ant-btn w-100 primary-btn" onClick={handleSaveSettingsChange}>
                  {t('QUICK_ACTION_SAVE')}
                </Button>
              ]}>
                    <Radio.Group onChange={handleSettingsChange} value={birthDayRule}>
                        <Space direction="vertical">
                            <Radio value={1}>{t('TIMEOFF_BIRTHDAY_SETTINGS_RULE_1')}</Radio>
                            <Radio value={2}>{t('TIMEOFF_BIRTHDAY_SETTINGS_RULE_2')}</Radio>
                            <Radio value={3}>{t('TIMEOFF_BIRTHDAY_SETTINGS_RULE_3')}</Radio>
                        </Space>
                    </Radio.Group>
            </Modal>
            <div className="pb-3">
                <Button className="w-180 primary-btn" onClick={() => createTimeoffTypeDraw()} icon={<PlusOutlined />}>{t('TIMEOFF_TYPE_CREATE')}</Button>
            </div>
            <Media query="(min-width: 875px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('TIMEOFF_NAME')}`,
                            dataIndex: 'name',
                            key: 'name',
                            sorter: true,
                            className:"timeofftype-name-col",
                            render: obj =>
                                <div className='d-flex align-items-center'>
                                    <Dropdown
                                    className="action-menu"
                                    overlay={timeoffTypeDropdown(t, [ obj.timeOffSetIdx !=9 && 'copy' , !obj.isDefault && 'delete', (!obj.isDefault && obj.timeOffSetIdx !=9) && 'edit', obj.timeOffSetIdx ==9 && 'editSettings'  ], confirmDeleteModal, obj.id , handleCopyTimeoffType, handleEditTimeoffType, handleEditSettingsTimeoffType)}
                                    trigger={['click']}
                                    >
                                        <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                    </Dropdown>

                                    <div className='mt-1'>{obj.name}</div>
                                </div>
                            
                        },
                        {
                            title: `${t('TIMEOFF_TYPE')}`,
                            dataIndex: 'paymentType',
                            key: 'paymentType',
                            sorter: true,
                        },
                        {
                            title: `${t('MAX_TIMEOFF_DURATION')}`,
                            dataIndex: 'maxBalance',
                            key: 'maxBalance',
                            sorter: true,
                        },
                        {
                            title: `${t('TIMEOFF_DURATION_UNIT')}`,
                            dataIndex: 'unit',
                            key: 'unit',
                            sorter: true,
                        },
                        {
                            title: `${t('TIMEOFF_TYPE_STATUS')}`,
                            dataIndex: 'isActive',
                            key: 'isActive',
                            sorter: true,
                            align: 'right',
                            render : obj => {
                                const annualLeave = data.filter(x => x.isActive.timeOffSetIdx === 1 && x.isActive.status)[0]?.name.name;
                                
                                return <div className='d-flex ml-auto align-items-center time-off-table-end'>
                                    {obj.timeOffSetIdx === 1 && obj.status === false && (
                                        <Tooltip title={t('TIMEOFF_SENIORITY_TOOLTIP', { annualLeave: annualLeave })}>
                                            <InfoCircleOutlined className="ml-2" />
                                        </Tooltip>
                                    )}
                                    <Switch 
                                        className="ml-2" 
                                        onChange={(e) => onChangeTimeoffTypeStatus(e, obj.id, obj.timeOffSetIdx)} 
                                        checked={obj.status} 
                                    />
                                </div>
                            }
                        }
                    ];
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={false}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={organizationTimeoffTypeListLoading}
                        hideScroll={true}
                    />
                }}
            </Media>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        organizationTimeoffTypeList : state.timeoffTypes.organizationTimeoffTypeList,
        organizationBirthdayRule : state.timeoffTypes.organizationBirthdayRule,
        organizationTimeoffTypeListLoading : state.timeoffTypes.organizationTimeoffTypeListLoading,
        updateStatusTimeoffTypeResponse: state.timeoffTypes.updateStatusTimeoffTypeResponse,
        deleteTimeoffTypeResponse: state.timeoffTypes.deleteTimeoffTypeResponse,
        copyTimeoffTypeResponse: state.timeoffTypes.copyTimeoffTypeResponse,
        notificationUpdateResponse: state.notificationReducer.notificationUpdateResponse,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getOrganizationTimeoffTypeList: () => { dispatch(getOrganizationTimeoffTypeList()) },
        getTimeoffTypeDetail: (id) => { dispatch(getTimeoffTypeDetail(id)) },
        changeTimeoffTypeStatus: (data) => { dispatch(changeTimeoffTypeStatus(data)) },
        deleteTimeoffType: (id) => { dispatch(deleteTimeoffType(id)) },
        copyTimeoffType: (id) => { dispatch(copyTimeoffType(id)) },
        updateTimeOffRequest: (id) => { dispatch(updateTimeOffRequest(id)) },
        updateTimeOffRequestReset: () => { dispatch(updateTimeOffRequestReset()) },
        resetTimeoffTypeResponses: () => { dispatch(resetTimeoffTypeResponses()) },
        setDetailTimeoffTypeDrawer : (status) => { dispatch(setDetailTimeoffTypeDrawer(status)) },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeOffTypesTab);