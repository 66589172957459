import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setDetailTimeoffTypeDrawer, updateTimeoffType, getOrganizationTimeoffTypeList, resetTimeoffTypeResponses, getTimeoffTypeDetail } from '../../../../redux/actions/timeoffTypes.action'; // Drawer Statusler

import { Col, Row, Button, Steps, List, Tabs, Spin } from 'antd';
import { UilMultiply} from '@iconscout/react-unicons'


import UpdateTimeoffTypeBasicInfo from './timeoffTypesBasicInfo';
import UpdateTimeoffTypeDetailInfo from './timeoffTypesDetailInfo';
import UpdateTimeoffTypeAppliedPersons from './timeoffTypesAppliedPersons';
// import AddTimeoffTypeSummary from './timeoffTypesSummary';
// import Successful from "../../../successful";

import DynamicRequestModal from '../../../dynamicModal/requestModal';
import DynamicToastMessage from '../../../dynamicToastMessage/index';

import '../../../../styles/table-draw.scss';
import '../../../../styles/table-add-draw.scss';
import '../../../../styles/left-step.scss';

import { deepDiffMapper, MergeObjects, resetScrollPosition, regex } from '../../../../utils/commonFormFunctions'


function UpdateTimeoffTypeTabList(props) {
    const { t } = useTranslation();

    const { setDetailTimeoffTypeDrawer, updateTimeoffType, updateTimeoffTypeResponse, getOrganizationTimeoffTypeList, resetTimeoffTypeResponses, timeoffTypeDetail,
         getTimeoffTypeDetail, isTimeoffTypeDetailStatus } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const defaultData = {
        "name": null,
        "paymentSetIdx": null,
        "description": null,
        "isBalanceUnlimited": null,
        "maxBalance": null,
        "unitSetIdx": null,
        "accrualUnitSetIdx": null,
        "accrualDateSetIdx": null,
        "accrualDuration": null,
        "accrualDurationTypeSetIdx": null,
        "isTransferable": false,
        "isExceedable": false,
        "maxExceedBalance": null,
        "isExceedableInform": false,
        "isGenderType": false,
        "genderTypeSetIdx": null,
        "isRenewalableBalance": false,
        "isRequiredDocument": false,
        "isAppearContactInfo": false,
        "isRequiredContactInfo": null,
        "isRequiredDescription": false,
        "isActive":false,
        "personList": null,
        // "departmentList": [
        //   0
        // ],
        "tagList": null
    }
    const headerList = {
        "1":t('TIMEOFF_STEP_BASIC_INFO'),
        "2":t('TIMEOFF_STEP_DETAIL_INFO'),
        "3":t('TIMEOFF_STEP_APPLIED_PERSONS'),
        "4":t('TIMEOFF_STEP_PREVIEW') ,
        "5":t('TIMEOFF_STEP_DONE')
    }
    // const [timeoffTypeUpdateObj, setUpdateTimeoffTypeObject] = useState(defaultData);
    const [confirmModalData, setConfirmModalData] = useState({ "isVisible": false });
    const [ timeoffTypeDetailRef, setTimeoffTypeDetailRef] = useState("{}");
    const [ timeoffTypeUpdateObj, setTimeoffTypeUpdateObj] = useState({});
    const [hasChanged, setHasChanged] = useState(false);

    const { Step } = Steps;
    const { TabPane } = Tabs;
    
    function callback(key) {
        setCurrentTab(parseInt(key))
    }

    function closeDrawerControl() {
        closeAddModal()
    }
    function closeAddModal() {
        setCurrentTab(1);
        setDetailTimeoffTypeDrawer(false);
        //setUpdateTimeoffTypeObject(defaultData);
    }

    const setUpdateTimeoffTypeObject = (o) => {
        console.log(o)
        
        // const diffObj = deepDiffMapper.map(JSON.parse(timeoffTypeDetailRef), o, [ "tagList", "personList", "isBalanceUnlimited","accrualDateType","isExceedable" ]);
        // console.log("diffObj",diffObj)
        const mergedObj = MergeObjects(timeoffTypeUpdateObj, o)

        setTimeoffTypeUpdateObj(mergedObj)
        const hasChanged = document.getElementsByClassName("is-changed-add")[0] || document.getElementsByClassName("is-changed")[0] !== undefined || document.getElementsByClassName("is-checked")[0] !== undefined || document.getElementsByClassName("is-changed-radio")[0] !== undefined;
        setHasChanged(hasChanged);
    }

    useEffect(() => {
        setTimeoffTypeDetailRef(JSON.stringify({ ...timeoffTypeDetail}))
    }, [timeoffTypeDetail])

    useEffect(() => {
        setTimeoffTypeUpdateObj(JSON.parse(timeoffTypeDetailRef))
    }, [timeoffTypeDetailRef])

    const checkRequiredFields = (nextFunc,updateObj) => {

        let hasError = false;
        let fields = [];
        if (!updateObj.name || updateObj.name?.trim() === "") {
            hasError = true;
            fields.push(t('TIMEOFF_NAME'));
        }
        if (!updateObj.paymentSetIdx) {
            hasError = true;
            fields.push(t('TIMEOFF_TYPE'));
        }
        if (!updateObj.isBalanceUnlimited?.toString()) {
            hasError = true;
            fields.push(t('TIMEOFF_DURATION'));
        }
        if (updateObj.isBalanceUnlimited == 0) {
            if (!updateObj.accrualUnitSetIdx) {
                hasError = true;
                fields.push(t('TIMEOFF_ACCRUAL_UNIT_SET'));
            }
            if (!updateObj.maxBalance) {
                hasError = true;
                fields.push(t('TIMEOFF_LIMITED_LIMIT'));
            }
            if (!updateObj.unitSetIdx) {
                hasError = true;
                fields.push(t('TIMEOFF_UNIT_SET'));
            }
            
        }
        if (!updateObj.accrualDateSetIdx) {
            hasError = true;
            fields.push(t('TIMEOFF_STARTING_TIME'));
        }
        if (updateObj.accrualDateSetIdx == 3) {
            if (!updateObj.accrualDuration) {
                hasError = true;
                fields.push(t('TIMEOFF_ACCRUAL_DATE_SET'));
            }
            if (!updateObj.accrualDurationTypeSetIdx) {
                hasError = true;
                fields.push(t('TIMEOFF_ACCRUAL_DATE_UNIT'));
            }
        }
        if (updateObj.isExceedable == true) {
            if (!updateObj.maxExceedBalance) {
                hasError = true;
                fields.push(t('TIMEOFF_TIMEOUT_MAX'));
            }
        }
        if (updateObj.isGenderType == true) {
            if (updateObj.genderType == null) {
                hasError = true;
                fields.push(t('PARTNER_GENDER'));
            }
        }
        if (updateObj.isAppearContactInfo == true) {
            if (updateObj.isRequiredContactInfo == null) {
                hasError = true;
                fields.push(t('TIMEOFF_CONTACT_INFO_OPT_NOT_QUESTION'));
            }
        }
        if (!hasError) {
            nextFunc();
        } else {
            DynamicToastMessage({
                'key': 'warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            });
        }
    }

    const [isUpdateLoading, setisUpdateLoading] = useState(false);

    const handleUpdateTimeoffType = () => {

        if(isUpdateLoading) return;
        setisUpdateLoading(true);
        setTimeout(() => {setisUpdateLoading(false);}, 1055) // double click prevent;
        const updateObj = {
            ...timeoffTypeUpdateObj,
            // "name": null,
            "paymentSetIdx": timeoffTypeUpdateObj.paymentType?.id,
            // "description": null,
            // "isBalanceUnlimited": null,
            "maxBalance": timeoffTypeUpdateObj.maxBalance && Number(timeoffTypeUpdateObj.maxBalance) || null,
            "unitSetIdx": timeoffTypeUpdateObj.unitType?.id,
            "accrualUnitSetIdx": timeoffTypeUpdateObj.accrualUnit?.id,
            "accrualDateSetIdx": timeoffTypeUpdateObj.accrualDateType,
            // "accrualDuration": null,
            "accrualDurationTypeSetIdx": timeoffTypeUpdateObj.accrualDurationType?.id,
            // "isTransferable": false,
            // "isExceedable": false,
            "maxExceedBalance": timeoffTypeUpdateObj.maxExceedBalance && Number(timeoffTypeUpdateObj.maxExceedBalance) || null,
            // "isExceedableInform": false,
            // "isGenderType": false,
            "genderTypeSetIdx": timeoffTypeUpdateObj.genderType,
            // "isRenewalableBalance": false,
            // "isRequiredDocument": false,
            // "isAppearContactInfo": false,
            // "isRequiredContactInfo": null,
            // "isRequiredDescription": false,
            // "isActive":false,
            // "personList": null,
            // "departmentList": [
            //   0
            // ],
            "tagList": timeoffTypeUpdateObj.tagList?.length >0 && timeoffTypeUpdateObj.tagList?.map((item) => item.id) || null
        }
        checkRequiredFields(() => updateTimeoffType(updateObj), updateObj);
    }


    useEffect(() => {
        if (updateTimeoffTypeResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'timeoff_type_update_success_notification',
                'title': t('SUCCESS'),
                'description': updateTimeoffTypeResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            if(timeoffTypeDetail?.id){
                getTimeoffTypeDetail(timeoffTypeDetail?.id)
            }else{
                setDetailTimeoffTypeDrawer(false);
            }
            getOrganizationTimeoffTypeList();
            resetTimeoffTypeResponses();
        } else if (updateTimeoffTypeResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'timeoff_type_create_fail_notification',
                'title': t('ERROR'),
                'description': updateTimeoffTypeResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetTimeoffTypeResponses();
        } 

    }, [updateTimeoffTypeResponse])

    return (
        <div>
            <DynamicRequestModal
                modalVisible={confirmModalData?.isVisible}
                setStateData={setConfirmModalData}
                postAction={closeAddModal}
                confirmData={null}
                iconName='UilQuestionCircle'
                modalTitle={null}
                description={t('DO_YOU_CONFIRM_CANCEL_ADD_NEW_TIMEOFF_TYPE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_CANCEL')}
            />
            <div className="add-user-close-icon">
                <UilMultiply onClick={() => closeDrawerControl()} />
            </div>

            {isTimeoffTypeDetailStatus == 'loading' ?
                <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
                :
                <div className="table-details timeoff-type-table-details">

                    <div className="w-100">
                        <div className="add-user-draw-title pb-4">
                            <h1 className="mb-0">{t('UPDATE_TIMEOFF_TYPE_MODAL_TITLE',{"name":timeoffTypeDetail?.name})}</h1>
                        </div>
                        <Row>
                            <Col span={24}>
                                <Tabs className="custom-tab update-timeoff-types-draw-scrollable-tab"
                                    onChange={callback}
                                    activeKey={currentTab.toString()}
                                    defaultActiveKey={currentTab.toString()}
                                >
                                    <TabPane tab={`${t('TIMEOFF_STEP_BASIC_INFO')}`} key={1}>
                                        <UpdateTimeoffTypeBasicInfo setUpdateTimeoffTypeObject={(o)=>{setUpdateTimeoffTypeObject(o)}}  timeoffType={timeoffTypeDetail}/>
                                    </TabPane>
                                    <TabPane tab={t('TIMEOFF_STEP_DETAIL_INFO')} key={2}>
                                        <UpdateTimeoffTypeDetailInfo setUpdateTimeoffTypeObject={(o)=>{setUpdateTimeoffTypeObject(o)}} timeoffType={timeoffTypeDetail}/>
                                    </TabPane>
                                    <TabPane tab={t('TIMEOFF_STEP_APPLIED_PERSONS')} key={3}>
                                        <UpdateTimeoffTypeAppliedPersons setUpdateTimeoffTypeObject={(o)=>{setUpdateTimeoffTypeObject(o)}} timeoffType={timeoffTypeDetail}/>
                                    </TabPane>
                                </Tabs>
                                {hasChanged && (
                                    <div className="fixed-btn">
                                        <div className="add-user-draw-footer timeoff-type-drawer-footer">
                                            <Button onClick={closeDrawerControl} className="w-120 h-40 transparent-btn pad-0">{t('INVENTORY_CANCEL')}</Button>
                                            <div className="add-user-draw-footer-end " >
                                                {/* <Can I="person.update"> */}
                                                    <Button onClick={handleUpdateTimeoffType} disabled={updateTimeoffTypeResponse.status !== 'loading' ? false:true} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_SAVE_CHANGES')}</Button>
                                                {/* </Can> */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        updateTimeoffTypeResponse : state.timeoffTypes.updateTimeoffTypeResponse,
        isTimeoffTypeDetailStatus : state.timeoffTypes.timeoffTypeDetailResponse?.status,
        timeoffTypeDetail : state.timeoffTypes.timeoffTypeDetailResponse?.data,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        setDetailTimeoffTypeDrawer: (status) => { dispatch(setDetailTimeoffTypeDrawer(status)) },
        updateTimeoffType: (data) => { dispatch(updateTimeoffType(data)) },
        getTimeoffTypeDetail: (id) => { dispatch(getTimeoffTypeDetail(id)) },
        getOrganizationTimeoffTypeList: () => { dispatch(getOrganizationTimeoffTypeList()) },
        resetTimeoffTypeResponses: () => { dispatch(resetTimeoffTypeResponses()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTimeoffTypeTabList);